// Topbar.js
import React from "react";
import { Link } from "gatsby";

import "../styles/parts/Nav.css";

const Nav = () => (
    <nav className="nav">
        <ul className="nav-list">
            <li className="nav-list-item">
                <Link
                    to="/">
                    Home
                </Link>
            </li>
            <li className="nav-list-item">
                <Link
                    to="/the-infosec-chronicles">
                    About
                </Link>
            </li>
            {/*<li className="nav-list-item">*/}
            {/*    <Link*/}
            {/*        to="/sitemap">*/}
            {/*        Sitemap*/}
            {/*    </Link>*/}
            {/*</li>*/}
        </ul>
    </nav>
);

export default Nav;