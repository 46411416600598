// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from 'react'

import { Link, StaticQuery, graphql } from 'gatsby'

import { kebabCase } from 'lodash'

const TagsComponent = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={data => (
      <aside className="tags-wrapper">
        <div className="comp-tags-wrapper">
          <h3 className="comp-tags-wrapper-title">Tags</h3>
          <div className="comp-tags-body">
            <ul className="tags">
              {data.allMarkdownRemark.group.map((tag) => (
                <li key={tag.fieldValue}>
                  <Link
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}
                    className="tag"
                    >
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </aside>
    )}
  />
)

export default TagsComponent