import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class Newsletter extends React.Component {

  constructor() {
    super()
    this.state = { email: "", result: null, subscribed: false  }
  }

  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const result = await addToMailchimp(event.target.email.value)
    console.log(result.msg)
    let stripedMsg = "FAILED TO SUBSCRIBE. PLEASE TRY AGAIN LATER.";
    if(result.msg.includes("is already subscribed to list")) {
      stripedMsg = "YOU ALREADY SUBSCRIBED TO THE NEWSLETTER."
    }
    console.log(stripedMsg)
    this.setState({result: result.result, message: stripedMsg, email: event.target.email.value})
  }

  render() {
    if(this.state.result == null) {
      return (
        <>
          <aside className="newsletter-wrapper">
            <div className="search">
              <section className="newsletter">
                <form onSubmit={this.handleSubmit}>
                  <h3 className="newsletter-title">Subscribe</h3>
                  <input className="search-box" type="email" placeholder="Email..." name="email"/>
                  <input className="search-button" type="submit" value="Subscribe"/>
                </form>
              </section>
            </div>
          </aside>
        </>
      )
    } else if(this.state.result === "success") {
      return (
        <>
          <aside className="newsletter-wrapper">
            <div className="search">
              <section className="newsletter">
                <form onSubmit={this.handleSubmit}>
                  <h3 className="newsletter-title">Subscribe</h3>
                  <p>SUBSCRIBED</p>
                </form>
              </section>
            </div>
          </aside>
        </>
      )
    } else if(this.state.result === "error") {
      return (
        <>
          <aside className="newsletter-wrapper">
            <div className="search">
              <section className="newsletter">
                <form onSubmit={this.handleSubmit}>
                  <h3 className="newsletter-title">Subscribe</h3>
                  <p>{this.state.message}</p>
                </form>
              </section>
            </div>
          </aside>
        </>
      )
    }
  }
}

export default Newsletter
