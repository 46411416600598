import React from "react";

import Logo from './logo.png'

const Header = () => (
    <div className="header-logo">
        <a href="/">
            <img src={Logo}/>
        </a>
    </div>
);

export default Header;