import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Sidebar from '.././components/sidebar'
import '../styles/main.scss'
import '../styles/fonts/font-awesome/css/font-awesome.min.css'
import Nav from "./Nav";
import Social from "./Social";
import Newsletter from "./Newsletter";
import Tags from "./tags";
import Header from "./Header";

const DefaultLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            author
            description
            social {
              twitter
              facebook
              linkedin
              github
              email
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato|PT+Serif&display=swap"
            rel="stylesheet"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </Helmet>
        <div className="header">
          <Header/>
        </div>
        <div className="global-wrapper">
          <div className="body-wrapper">
            {/*<Sidebar siteMetadata={data.site.siteMetadata} />*/}
            <main>{children}</main>
          </div>
          <div className="rightbar-wrapper">
            <Social siteMetadata={data.site.siteMetadata}/>
            <Newsletter/>
            <Tags/>
          </div>
        </div>
        <div className="footer">
          <Nav/>
        </div>
      </div>
    )}
  />
)

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
